// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Role } from './Role'
import { RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from './Role'
import type { SimpleClassroomRepresentation } from './SimpleClassroomRepresentation'
import {
  SimpleClassroomRepresentationFromJSON,
  SimpleClassroomRepresentationFromJSONTyped,
  SimpleClassroomRepresentationToJSON,
} from './SimpleClassroomRepresentation'
import type { SimpleSchoolRepresentation } from './SimpleSchoolRepresentation'
import {
  SimpleSchoolRepresentationFromJSON,
  SimpleSchoolRepresentationFromJSONTyped,
  SimpleSchoolRepresentationToJSON,
} from './SimpleSchoolRepresentation'

/**
 *
 * @export
 * @interface StudentProfileRepresentation
 */
export interface StudentProfileRepresentation {
  /**
   *
   * @type {string}
   * @memberof StudentProfileRepresentation
   */
  userId: string
  /**
   *
   * @type {number}
   * @memberof StudentProfileRepresentation
   */
  blakeId: number
  /**
   *
   * @type {string}
   * @memberof StudentProfileRepresentation
   */
  schoolId: string
  /**
   *
   * @type {number}
   * @memberof StudentProfileRepresentation
   */
  blakeSchoolId: number
  /**
   *
   * @type {string}
   * @memberof StudentProfileRepresentation
   */
  username: string
  /**
   *
   * @type {Role}
   * @memberof StudentProfileRepresentation
   */
  role: Role
  /**
   *
   * @type {string}
   * @memberof StudentProfileRepresentation
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof StudentProfileRepresentation
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof StudentProfileRepresentation
   */
  dna: string
  /**
   *
   * @type {string}
   * @memberof StudentProfileRepresentation
   */
  avatar: string
  /**
   *
   * @type {SimpleClassroomRepresentation}
   * @memberof StudentProfileRepresentation
   */
  classroom?: SimpleClassroomRepresentation
  /**
   *
   * @type {SimpleSchoolRepresentation}
   * @memberof StudentProfileRepresentation
   */
  school: SimpleSchoolRepresentation
  /**
   *
   * @type {boolean}
   * @memberof StudentProfileRepresentation
   */
  active: boolean
  /**
   *
   * @type {string}
   * @memberof StudentProfileRepresentation
   */
  studentProfileSnapshotId: string
  /**
   *
   * @type {number}
   * @memberof StudentProfileRepresentation
   */
  gradePosition: number
  /**
   *
   * @type {number}
   * @memberof StudentProfileRepresentation
   */
  gradeSetId: number
  /**
   *
   * @type {boolean}
   * @memberof StudentProfileRepresentation
   */
  isDemoStudent: boolean
  /**
   *
   * @type {boolean}
   * @memberof StudentProfileRepresentation
   */
  isOneRosterActive?: boolean
}

/**
 * Check if a given object implements the StudentProfileRepresentation interface.
 */
export function instanceOfStudentProfileRepresentation(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'userId' in value
  isInstance = isInstance && 'blakeId' in value
  isInstance = isInstance && 'schoolId' in value
  isInstance = isInstance && 'blakeSchoolId' in value
  isInstance = isInstance && 'username' in value
  isInstance = isInstance && 'role' in value
  isInstance = isInstance && 'firstName' in value
  isInstance = isInstance && 'lastName' in value
  isInstance = isInstance && 'dna' in value
  isInstance = isInstance && 'avatar' in value
  isInstance = isInstance && 'school' in value
  isInstance = isInstance && 'active' in value
  isInstance = isInstance && 'studentProfileSnapshotId' in value
  isInstance = isInstance && 'gradePosition' in value
  isInstance = isInstance && 'gradeSetId' in value
  isInstance = isInstance && 'isDemoStudent' in value

  return isInstance
}

export function StudentProfileRepresentationFromJSON(
  json: any,
): StudentProfileRepresentation {
  return StudentProfileRepresentationFromJSONTyped(json, false)
}

export function StudentProfileRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentProfileRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userId: json['user_id'],
    blakeId: json['blakeId'],
    schoolId: json['schoolId'],
    blakeSchoolId: json['blakeSchoolId'],
    username: json['username'],
    role: RoleFromJSON(json['role']),
    firstName: json['first_name'],
    lastName: json['last_name'],
    dna: json['dna'],
    avatar: json['avatar'],
    classroom: !exists(json, 'classroom')
      ? undefined
      : SimpleClassroomRepresentationFromJSON(json['classroom']),
    school: SimpleSchoolRepresentationFromJSON(json['school']),
    active: json['active'],
    studentProfileSnapshotId: json['studentProfileSnapshotId'],
    gradePosition: json['gradePosition'],
    gradeSetId: json['gradeSetId'],
    isDemoStudent: json['isDemoStudent'],
    isOneRosterActive: !exists(json, 'isOneRosterActive')
      ? undefined
      : json['isOneRosterActive'],
  }
}

export function StudentProfileRepresentationToJSON(
  value?: StudentProfileRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_id: value.userId,
    blakeId: value.blakeId,
    schoolId: value.schoolId,
    blakeSchoolId: value.blakeSchoolId,
    username: value.username,
    role: RoleToJSON(value.role),
    first_name: value.firstName,
    last_name: value.lastName,
    dna: value.dna,
    avatar: value.avatar,
    classroom: SimpleClassroomRepresentationToJSON(value.classroom),
    school: SimpleSchoolRepresentationToJSON(value.school),
    active: value.active,
    studentProfileSnapshotId: value.studentProfileSnapshotId,
    gradePosition: value.gradePosition,
    gradeSetId: value.gradeSetId,
    isDemoStudent: value.isDemoStudent,
    isOneRosterActive: value.isOneRosterActive,
  }
}
