// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const StudentTaskTypeOrder = {
  Assignment: 'assignment',
  PlacementTest: 'placement-test',
  Quest: 'quest',
} as const
export type StudentTaskTypeOrder =
  (typeof StudentTaskTypeOrder)[keyof typeof StudentTaskTypeOrder]

export function StudentTaskTypeOrderFromJSON(json: any): StudentTaskTypeOrder {
  return StudentTaskTypeOrderFromJSONTyped(json, false)
}

export function StudentTaskTypeOrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentTaskTypeOrder {
  return json as StudentTaskTypeOrder
}

export function StudentTaskTypeOrderToJSON(
  value?: StudentTaskTypeOrder | null,
): any {
  return value as any
}
