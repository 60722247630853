// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { StudentTaskTypeOrder } from './StudentTaskTypeOrder'
import {
  StudentTaskTypeOrderFromJSON,
  StudentTaskTypeOrderFromJSONTyped,
  StudentTaskTypeOrderToJSON,
} from './StudentTaskTypeOrder'

/**
 *
 * @export
 * @interface StudentCurrentTaskRepresentation
 */
export interface StudentCurrentTaskRepresentation {
  /**
   *
   * @type {StudentTaskTypeOrder}
   * @memberof StudentCurrentTaskRepresentation
   */
  taskType: StudentTaskTypeOrder
  /**
   *
   * @type {string}
   * @memberof StudentCurrentTaskRepresentation
   */
  precinct: string
  /**
   *
   * @type {string}
   * @memberof StudentCurrentTaskRepresentation
   */
  taskActivityId: string
  /**
   *
   * @type {string}
   * @memberof StudentCurrentTaskRepresentation
   */
  assignmentId?: string | null
  /**
   *
   * @type {number}
   * @memberof StudentCurrentTaskRepresentation
   */
  assignmentTaskId?: number | null
  /**
   *
   * @type {Date}
   * @memberof StudentCurrentTaskRepresentation
   */
  endAtUtc?: Date | null
}

/**
 * Check if a given object implements the StudentCurrentTaskRepresentation interface.
 */
export function instanceOfStudentCurrentTaskRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'taskType' in value
  isInstance = isInstance && 'precinct' in value
  isInstance = isInstance && 'taskActivityId' in value

  return isInstance
}

export function StudentCurrentTaskRepresentationFromJSON(
  json: any,
): StudentCurrentTaskRepresentation {
  return StudentCurrentTaskRepresentationFromJSONTyped(json, false)
}

export function StudentCurrentTaskRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentCurrentTaskRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    taskType: StudentTaskTypeOrderFromJSON(json['taskType']),
    precinct: json['precinct'],
    taskActivityId: json['taskActivityId'],
    assignmentId: !exists(json, 'assignmentId')
      ? undefined
      : json['assignmentId'],
    assignmentTaskId: !exists(json, 'assignmentTaskId')
      ? undefined
      : json['assignmentTaskId'],
    endAtUtc: !exists(json, 'end_at_utc')
      ? undefined
      : json['end_at_utc'] === null
        ? null
        : new Date(json['end_at_utc']),
  }
}

export function StudentCurrentTaskRepresentationToJSON(
  value?: StudentCurrentTaskRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    taskType: StudentTaskTypeOrderToJSON(value.taskType),
    precinct: value.precinct,
    taskActivityId: value.taskActivityId,
    assignmentId: value.assignmentId,
    assignmentTaskId: value.assignmentTaskId,
    end_at_utc:
      value.endAtUtc === undefined
        ? undefined
        : value.endAtUtc === null
          ? null
          : value.endAtUtc.toISOString(),
  }
}
